<template>
  <div class="process-item">
    <div class="tit"> {{getBalance}}、{{processData.title}}</div>
    <div class="caption">{{processData.caption}}</div>
    <div class="highlightText" v-if="processData.highlightText">{{processData.highlightText}}</div>
    <div class="imgFile">
      <div class="img-item" v-for="(item,index) in processData.imgFile" :key="index">
        <img :src="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    processData: {
      type: Object,
      default: ''
    },
    index: {
      type: Number,
      default: ''
    }
  },
  computed: {
    getBalance() {
      if (Number(this.index) >= 0) {
        switch (this.index) {
          case 0:
            return '一'
          case 1:
            return '二'
          case 2:
            return '三'
          case 3:
            return '四'
          case 4:
            return '五'
          case 5:
            return '六'
          default:
            break;
        }
      } else {
        return 0
      }
    }
  },
  data () {
    return {

    }
  },
  components: {

  },
  mounted() {

  },
  methods: {

  },
}

</script>
<style scoped lang='less'>
.process-item {
  .tit {
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: linear-gradient(90deg, #FE7E75 0%, #F5422E 100%);
    border-radius: 18px 18px 18px 18px;
    opacity: 1;
    margin: 20px 0 23px 20px;
    font-size: 14px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
    color: #FFFFFF;
    display: inline-block;
    padding: 0 15px;
  }
  .caption {
    font-size: 14px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
    color: #333333;
    margin: 0 27px 11px 28px;
  }
  .highlightText {
    height: 36px;
    line-height: 36px;
    background: #FFF2F2;
    border-radius: 17px 17px 17px 17px;
    padding: 0 22px;
    display: inline-block;
    font-size: 14px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
    color: #FF5E51;
    margin-left: 28px;
  }
  .imgFile {
    display: flex;
    margin-left: 28px;
    .img-item {
      width: 104px;
      height: 98px;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #E7E7E7;
      padding: 9px 12px;
      margin-top: 17px;
      margin-right: 12px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px 6px 6px 6px;
      }
    }
  }
}
</style>
<template>
  <div class="playback-item" @click="seeDetails(playbackData)">
    <div class="title">
      <template v-if="IsPurchase">
        <!-- isLive: 0 未开始 1播放中 2回放生成中 3回放中 -->
        <img v-if="playbackData.isLive == 1" src="@/assets/img/Home/oneToOne/living.png">
        <img v-else src="@/assets/img/Home/oneToOne/playback-icon.png">
      </template>
      <p class="twoEllipsis">{{ playbackData.title }}</p>
      <div class="isLive" v-if="playbackData.ksTime">{{ playbackData.ksTime }} 课时</div>
    </div>
    <div class="time" v-if="IsPurchase">
      <img src="@/assets/img/Home/oneToOne/playback-time.png">
      <div>{{ playbackData.startTime }}</div>
    </div>
  </div>
</template>

<script>
import { getInfo } from "@/api/cookies"
import { courseCheckPlay } from "@/api/know";
import Vue from "vue";
export default {
  props: {
    playbackData: {
      type: Object,
      default: () => {
        return null
      }
    },
    // 是否购买
    IsPurchase: {
      type: Boolean,
      default: false
    },
    // 课程详情
    detailList: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data () {
    return {
    }
  },
  components: {

  },
  mounted() {

  },
  methods: {
    seeDetails(item) {
      let userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (!this.IsPurchase && item.isTrial != 1) {
        this.$emit('handleOpen', true)
        return
      }
      if (item.isLive == 0) {
        this.$message.error("课程还未到开播时间")
        return
      }
      if (item.isLive == 2) {
        this.$message.error("课程直播结束")
        return
      }
      if (this.detailList.isFreeze == 1) {
        this.$message.error("课程已冻结")
        return
      }
      let sub = {
        id: item.id,
        classId: this.$route.query.id,
        startTime: item.startTime,
        classProductLine: this.detailList.productLine,
        IsPurchase: this.IsPurchase,
        chapterName: item.title,
      }
      for (let key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key];
        }
      }
      let data = Vue.prototype.export(sub)
      
      courseCheckPlay({
        courseType: 10,
        userId: userInfo.id,
        chapterId: item.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          let routeUrl = this.$router.resolve({
            path: `/oneToOneLive?${data}`
          })
          window.open(routeUrl.href, "_blank")
        } else {
          this.showLookDetail = true
          this.$emit('handleShowLookDetail', {
            status: true,
            lookDeatil: res.msg
          },)
          this.goBack = false;
        }
      });
    }
  },
}

</script>
<style scoped lang='less'>
.playback-item {
  width: 759px;
  background: #F6F7F9;
  margin-bottom: 18px;
  padding: 18px 0 16px 18px;
  cursor: pointer;
  .title {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #444444;
    display: flex;
    align-items: center;
    > img {
      width: 19px;
      height: 19px;
    }
    p {
      margin: 0 12px 0 14px;
      cursor: pointer;
      max-width: 620px;
    }
    .isLive {
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: #FFECE8;
      border-radius: 18px 18px 18px 18px;
      font-size: 13px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #FF5E51;
      padding: 0 10px;
    }
  }
  .time {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #666666;
    margin-top: 17px;
    >img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
}
</style>
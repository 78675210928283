<template>
  <div class="teacher-introduce">
    <div class="tit">
      讲师介绍
      <img class="icon" src="@/assets/img/Home/tips.png" />
    </div>
    <div class="scroll-list">
      <div class="list" v-for="(item, index) in teacherList" :key="index">
        <img @click="goDetails(item)" class="terImg" :src="item.terImg" alt="">
        <div class="list-msg">
          <div class="nickname">{{ item.nickname }}</div>
          <div class="graduateName"><span class="icon">院校</span>{{ item.graduateName }}</div>
          <div class="majorName"> <span class="icon">专业</span>{{ item.majorName }}</div>
          <div class="profile" v-if="item.profile" v-html="ImgSizeLimit(item.profile)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teacherList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {

    }
  },
  components: {

  },
  mounted() {

  },
  methods: {
    goDetails(item) {
      let routeUrl = this.$router.resolve({
        path: `/faculty/teacherDetail`,
        query: {
            id: item.id
        }
      })
      window.open(routeUrl.href, '_blank')
    },
  },
}

</script>
<style scoped lang='less'>
.teacher-introduce {
  width: 374px;
  max-height: 826px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px 0 23px 17px;
  .tit {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #333333;
    margin: 0 0 27px 3px;
    position: relative;
    display: inline-block;
    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .scroll-list {
    overflow: scroll;
    max-height: 760px;
  }
  .list {
    display: flex;
    margin-bottom: 26px;
    .terImg {
      width: 147px;
      height: 220px;
      margin-right: 17px;
      cursor: pointer;
    }
    .list-msg {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      .nickname {
        font-size: 20px;
        font-family: MicrosoftYaHei;
        color: #333333;
        margin-bottom: 22px;
      }
      .majorName {
        margin: 12px 0;
      }
      .graduateName, .majorName {
        height: 25px;
        display: flex;
        align-items: center;
        .icon {
          background: url('~@/assets/img/Home/oneToOne/teacher-introduce-icon.png');
          width: 37px;
          height: 25px;
          background-size: 37px 25px;
          display: inline-block;
          line-height: 25px;
          text-align: center;
          margin-right: 10px;
          font-size: 12px;
          font-family: MicrosoftYaHei-, MicrosoftYaHei;
          font-weight: normal;
          color: #FFDAB5;
        }
      }
      .profile {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        margin-right: 23px;
      }
    }
  }
}
</style>
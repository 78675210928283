<template>
  <div class="oneToOneClass">
    <!-- 顶部banner图 -->
    <div class="bannerWrap">
      <div class="itroductionWrap">
        <div class="itroductionLeft">
          <div class="itroductionLeftImg">
            <img :src="detailList ? detailList.img : ''" />
            <div class="logo">
              <div class="msg">1对1直播教学辅导 <span class="msgs">量身定制课程+专业辅导</span></div>
            </div>
          </div>
          <div></div>
        </div>
        <div class="itroductionRight">
          <div class="itroductionTitle twoEllipsis">
            {{ detailList.tiitle ? detailList.tiitle : "" }}
          </div>
          <div class="introduce">
            <div class="introduce-tit">专业名称：<span>{{ detailList.zyName }}</span></div>
            <div class="introduce-tit">主讲老师：<span style="margin-right: 16px;" v-for="(item, index) in teacherName"
                :key="index">{{ item.nickname }}</span></div>
            <div class="introduce-tit">课程特色：
              <span v-for="item in featureList" :key="item.type" class="feature">
                <img src="@/assets/img/Home/oneToOne/love.png" />
                {{ item.text }}
              </span>
            </div>
            <div class="introduce-tit">课程有效期:
              <span v-if="detailList.validity">{{ detailList.validity }}年</span>
              <span v-else>{{ detailList.validStartTime }}-{{ detailList.validEndTime }}</span>
            </div>
          </div>
          <div class="bot-wrap">
            <div class="purchased" v-if="IsPurchase">
              <div class="btn"><i class="iconfont icon-duihao"></i>已购买</div>
            </div>
            <template v-else>
              <div class="price">￥{{ detailList.entrantsPrice }}</div>
              <div class="sub-btn" @click="pay">立即购买</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-title">
      <div class="item" v-for="(item, index) in tabList" :key="index">
        <div v-if="!item.show" class="title-text" :class="navIndex == item.type ? 'active' : ''"
          @click="navs(item.type)">
          {{ item.text }}
        </div>
        <div v-if="navIndex == item.type" class="on"></div>
      </div>
    </div>
    <div class="nav-content">
      <!-- <div class=""></div> -->
      <!-- 选项栏 -->

      <!-- 1对1班型介绍 -->
      <div class="warpCon warpCons" v-if="navIndex == 1">
        <div class="appleLefts" v-if="detailList.content" v-html="ImgSizeLimit(detailList.content)"></div>
        <div class="nothing" v-if="!detailList || !detailList.content">
          <div class="nothing-text">暂无内容哦~</div>
        </div>
      </div>
      <!-- 辅导流程 -->
      <div class="warpCon process-wrap" v-if="navIndex == 2">
        <process-item v-for="(item, index) in processPcList" :processData="item" :index="index" :key="index" />
      </div>

      <!-- 学友课表 -->
      <div class="playback-List" v-if="navIndex == 3">
        <div class="playback-msg" v-if="!IsPurchase">
          <div class="playback-bg">
            <div class="tit">课表介绍</div>
            <div>针对性训练，剖析讲解；由讲师和学员课下商定好时间和课程内容；提供既往课程为学员参考。</div>
          </div>
        </div>
        <div class="playback-wrap">
          <!-- 课表列表 -->
          <playback-list :IsPurchase="IsPurchase" v-for="(item, index) in PlaybackPcList" :key="index"
            :detailList="detailList" :playbackData="item" @handleOpen="handleOpen"
            @handleShowLookDetail="handleShowLookDetail" />
          <div class="empty" v-if="PlaybackPcList && !PlaybackPcList.length">
            <img src="@/assets/img/Home/oneToOne/empty.png" alt="">
            当前暂没有直播课，请与您的专属助教和讲师沟通上课时间
          </div>
        </div>
      </div>
      <teacher-introduce :teacherList="teacherList" v-if="id" :id="id" />
    </div>
    <div class="warpCpons" @touchmove.stop.prevent="stop()" v-if="dialogVisible">
      <div>
        <img class="imga" src="@/assets/img/kecheng/No1.png" />
        <img class="imgb" src="@/assets/img/kecheng/close.png" @click="onClosed" />
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>
    <!-- 是否可以观看弹窗 -->
    <div class="lookBg" v-if="showLookDetail">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDetail ? lookDetail.terminal : "" }}</span>观看<span>{{ lookDetail ? lookDetail.chapterName :
            ""
            }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import {
  getStuPcDetails,
  selectPcTerList,
  selectProcessPcList,
  selectPlaybackPcList,
  getClassCoursePackageVip,
} from "@/api/home.js";
import { getToken } from "@/api/cookies"
import processItem from "@/components/Know/onToOne/processItem.vue"
import teacherIntroduce from "@/components/Know/onToOne/teacherIntroduce.vue"
import playbackList from "@/components/Know/onToOne/playbackList.vue"
import { Know } from "@/api/know"
let know = new Know();
export default {
  components: {
    processItem,
    teacherIntroduce,
    playbackList
  },
  data() {
    return {
      id: '', // 班型ID
      detailList: [], // 详情
      IsPurchase: false, // 是否购买
      teacherList: [], // 老师列表
      teacherName: [], // 老师名字列表
      processPcList: [], // 辅导流程
      PlaybackPcList: [], // 学友课表
      featureList: [
        { text: "1对1直播", type: 1 },
        { text: "直面真题", type: 2 },
        { text: "专属教务", type: 3 },
        { text: "共同协商时间", type: 4 }
      ],
      userInfo: null,
      cardCode: null, // 学习卡id
      isFreeze: 0,
      tabList: [
        { text: "1对1班型介绍", type: 1 },
        { text: "辅导流程", type: 2 },
        { text: "学友课表", type: 3 },
      ],
      navIndex: 1,
      loading: this.$loading({
        lock: true,
        text: "加载中",
        spinner: "",
        background: "rgba(0, 0, 0, 0.1)",
      }),
      dialogVisible: false,
      showLookDetail: false,
      lookDetail: {}
    };
  },

  async created() {
    this.cardCode = this.$route.query.cardCode
    this.id = await this.$route.query.id
    this.getClass()
    this.getPcTerList()
    this.getProcessPcList()
    this.getPlaybackPcList()
  },
  methods: {
    // 点击立即报名按钮 跳转支付页
    pay() {
      let token = getToken();
      if (token) {
        this.$router.push(
          `/orders/classOrder?classId=${this.id}&type=oneToOne`
        );
      } else {
        Vue.prototype.goLoginView(true);
      }
    },
    // VIP详情课程
    async getClass() {
      const { data } = await getStuPcDetails(this.id)
      this.detailList = data.details
      this.IsPurchase = data.IsPurchase
      if (this.IsPurchase) {
        this.tabList = [
          { text: "课程列表", type: 3 },
          { text: "1对1班型介绍", type: 1 },
          { text: "辅导流程", type: 2 },
        ]
        this.navIndex = 3
      }
      this.loading.close();
    },
    // 老师列表
    async getPcTerList() {
      const { data } = await selectPcTerList(this.id)

      this.teacherList = data.slice(0, 8)
      this.teacherName = data.slice(0, 8)
    },
    // 辅导流程
    async getProcessPcList() {
      const { data } = await selectProcessPcList()
      this.processPcList = data
      this.processPcList.forEach((element, index, arr) => {
        if (element.imgFile) {
          arr[index].imgFile = element.imgFile.split(',')
        }
      })

    },
    // 学友课表
    async getPlaybackPcList() {
      const { data } = await selectPlaybackPcList({
        classesId: this.id,
        stuId: localStorage.getItem("userId"),
        isPurchase: this.IsPurchase ? 1 : 0 // 1 已购买 0未购买
      })
      this.PlaybackPcList = data
    },
    // 标签页切换
    navs(val) {
      this.navIndex = val;
    },

    getClassCoursePackageVip() {
      let data = {
        id: this.id,
        userid: this.userInfo,
      };
      getClassCoursePackageVip(data).then((res) => {
        if (res.code == 0) {
          this.haveBought = res.data;
        }
      });
    },

    getPackageVip() {
      let data = {
        id: this.id,
        userid: this.userInfo,
      };
      getClassCoursePackageVip(data).then((res) => {
        if (res.code == 0) {
          this.haveBought = res.data;
        }
      });
    },
    handleOpen(status) {
      this.dialogVisible = status
    },
    onClosed() {
      this.dialogVisible = false
    },
    handleShowLookDetail(data) {
      this.lookDetail = data.lookDeatil
      this.showLookDetail = data.status
    },
    closeLook() {
      this.showLookDetail = false
    },
  },
};
</script>

<style lang="less" scoped>
.oneToOneClass {
  .bannerWrap {
    width: 100%;
    height: 429px;
    background: url('~@/assets/img/Home/oneToOne/banner-bg.png');
    background-size: 100% 429px;

    .itroductionWrap {
      width: 1244px;
      display: flex;
      border-radius: 8px;
      margin: 0 auto;
      padding-top: 89px;

      .itroductionLeft {
        margin-right: 26px;

        .itroductionLeftImg {
          width: 514px;
          height: 290px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            position: relative;
            z-index: 2;
          }

          .logo {
            width: 368px;
            height: 32px;
            position: absolute;
            top: -28px;
            left: 5px;
            background: url('~@/assets/img/Home/oneToOne/1v1-top-log.png');
            background-size: 368px 32px;
            padding-left: 23px;
            line-height: 32px;
            z-index: 1;
          }

          .msg {
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
            font-weight: normal;
            color: #FFFFFF;

            .msgs {
              font-size: 13px;
              font-family: MicrosoftYaHei-, MicrosoftYaHei;
              font-weight: normal;
              margin-left: 35px;
            }
          }
        }

        .crumbs {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #d8c7b8;
          margin-top: 20px;
        }
      }

      .itroductionRight {
        position: relative;

        .itroductionTitle {
          font-size: 30px;
          font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
          font-weight: 700;
          color: #FFFFFF;
          margin-bottom: 26px;
        }

        .introduce {
          .introduce-tit {
            font-size: 16px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            color: #B9B9B9;
            margin-bottom: 12px;

            span {
              color: #FFFFFF
            }
          }

          .feature {
            background: linear-gradient(35deg, #E08F8A 0%, #F5422E 100%);
            box-shadow: 2px 0px 10px 1px rgba(141, 48, 8, 0.33);
            height: 22px;
            line-height: 22px;
            padding: 2px 14px 2px 8px;
            border-radius: 10px;
            margin-right: 13px;
            font-size: 12px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            color: #FFFFFF;

            img {
              width: 14px;
              height: 14px;
              display: inline-block;
              margin-bottom: -2px;
            }
          }
        }

        .bot-wrap {
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: flex-end;

          .purchased {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            color: #C0C0C0;

            .btn {
              width: 164px;
              height: 46px;
              line-height: 46px;
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              border: 2px solid #FF5E51;
              font-size: 18px;
              font-family: MicrosoftYaHei-, MicrosoftYaHei;
              font-weight: normal;
              color: #FF5E51;
              margin-right: 18px;
              text-align: center;
            }
          }

          .price {
            font-size: 28px;
            font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
            font-weight: normal;
            color: #FF5E51;
          }

          .sub-btn {
            width: 188px;
            height: 50px;
            background: #FF5E51;
            line-height: 50px;
            font-size: 18px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            color: #FFFFFF;
            text-align: center;
            margin-left: 49px;
            border-radius: 4px 4px 4px 4px;
            cursor: pointer;
          }
        }

        .label-12 {
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #aeaeae;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.4);
          margin-top: 12px;
          margin-bottom: 14px;

          .validPeriod {
            font-family: MicrosoftYaHei;
            font-size: 12px;
            color: #f3f3f3;
            font-weight: 400;
            line-height: 18px;
          }

          .label-12-Title {
            margin-right: 22px;
            flex-shrink: 0;
          }
        }

        .label-14 {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.4);

          // .el-select {
          //   width: 213px !important;
          //   height: 34px;
          //   border-radius: 4px;
          //   border: 1px solid #979797;
          // }
        }

        .btnWrap {
          position: absolute;
          // margin-top: 14px;
          bottom: 0;
          display: flex;

          .consult {
            width: 120px;
            height: 40px;
            border-radius: 4px;
            text-align: center;
            line-height: 40px;
            border: 1px solid white;
            color: white;
            margin-right: 16px;
            cursor: pointer;
          }

          .shop {
            width: 168px;
            height: 45px;
            background: linear-gradient(87deg, #d6926a 0%, #a16546 100%);
            border-radius: 4px;
            border: 1px solid #ffffff;
            text-align: center;
            line-height: 45px;
            cursor: pointer;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #ffffff;
          }
        }

        .label-14 /deep/ .el-input__inner {
          width: 213px;
          height: 34px;
          border-radius: 4px;
          border: 1px solid #979797;
          background-color: rgba(0, 0, 0, 0);
          color: #ffffff;
        }

        .link-14 {
          font-size: 14px;
          color: #ffffff;
          display: flex;
          align-items: center;
          margin-bottom: 21px;
        }

        .priceBlock {
          width: 468px;
          height: 60px;
          background-image: url("~@/assets/img/Home/wan_vip.png");
          background-size: cover;
          border-radius: 4px;
          // border: 1px solid #d79f75;
          line-height: 60px;
          display: flex;
          align-items: center;
          margin-top: 21px;

          .priceWrap {
            margin-left: 12px;
            // margin-right: 127px;
            display: flex;

            .label {
              font-size: 12px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #7e756d;
              color: #666666;
              font-family: Microsoft YaHei-Regular;
              margin-top: 5px;
            }

            .symbol {
              font-size: 30px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #ff6051;
              margin-left: 13px;
            }

            .price {
              font-size: 18px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #ff6051;
              font-size: 30px;
            }
          }

          .labelWrap {
            width: 140px;
            height: 36px;
            background-image: url("../..//assets/img/Home/Subtract.png");
          }

          .receiveBtn {
            width: 54px;
            height: 36px;
            background-image: url("../..//assets/img/Home/receiveBtn.png");
            font-size: 12px;
            color: #9a8257;
            line-height: 36px;
            text-align: center;
          }
        }

        .priceWrap1 {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #7e756d;
          margin-left: 56px;
        }

        .hint {
          margin-top: 13px;
          color: rgba(255, 255, 255, 0.4);
          font-size: 12px;
        }
      }
    }

    .fiveBlock {
      width: 1046px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      // margin-bottom: 30px;
      margin-top: 32px;

      .blockItem {
        width: 180px;
        height: 60px;
        border-radius: 6px;
        background-image: url("../../assets/img/Home/wan_vip1.png");
        background-size: cover;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin: 6px 17px;
          width: 48px;
          height: 48px;
          display: inline-block;
        }

        span {
          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: #782201;
        }
      }
    }
  }
}

.on {
  background-color: #f5f6f6;
  color: #ff8839;
}

.nav-content {
  width: 1200px;
  margin: 10px auto 60px;
  display: flex;
  justify-content: space-between;
}

.tabs-title {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  height: 57px;
  line-height: 57px;
  border-bottom: 7px solid #e9e9e9;
  margin-bottom: 23px;

  .item {
    position: relative;
    cursor: pointer;
    font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #444444;
    margin-right: 60px;
  }

  .title-text {
    font-size: 18px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
    color: #333333;
  }

  .active {
    color: #ff5e51;
  }

  .on {
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    width: 58px;
    height: 4px;
    background: #FF5E51;
    border-radius: 2px;
  }
}

.warpCons {
  width: 805px;
  padding: 27px;
  background: #fff;
}

.process-wrap {
  width: 805px;
  background: #fff;
  padding: 14px 0 30px;
}

.playback-List {
  width: 805px;

  .playback-msg {
    width: 805px;
    height: 221px;
    background: #FFFFFF;
    padding: 20px;
    margin-bottom: 20px;

    .playback-bg {
      width: 765px;
      height: 181px;
      background: url('~@/assets/img/Home/oneToOne/palyback-bg.png');
      font-size: 14px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #444444;
      padding: 29px 209px 0 64px;
      line-height: 27px;

      .tit {
        font-size: 20px;
        font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
        color: #333333;
        margin-bottom: 18px;
      }
    }
  }

  .playback-wrap {
    width: 805px;
    background: #FFFFFF;
    padding: 24px 27px 24px 19px;
  }

  .empty {
    margin: 97px 0 63px 132px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
    color: #8F9091;

    img {
      width: 83px;
      height: 83px;
    }
  }
}

::v-deep .appleLefts {
  img {
    margin: 20px auto;
  }
}

.jgLogo {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;

  img {
    display: block;
    height: 30px;
    width: auto !important;
  }
}

.warpCpons {
  // position: absolute;
  position: fixed;
  z-index: 2000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.6);

  div {
    width: 610px;
    height: auto;
    // margin-top: 400px;
    display: flex;
    z-index: 20;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0);

    .imga {
      width: 100%;
      height: 420px;
    }

    .imgb {
      width: 36px;
      height: 36px;
      margin-top: 30px;
      cursor: pointer;
    }

    span {
      width: 100%;
      height: auto;
      text-align: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #6c90fe;
      margin-top: -160px;
    }
  }
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  border: 1px solid #ccc;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
